import { EncodingExtensions as Base } from 'core/extensions/EncodingExtensions'
import { __, T } from 'stylewhere/shared/i18n'

export class EncodingExtensions extends Base {
  static throwNoConfigurationError(): never {
    throw new Error(__(T.error.nfc_already_associated))
  }

  static getErrorCode = (error) => {
    let errorCode: string = error.errorCode
    if (errorCode === 'Tag Mismatch') errorCode = __(T.error.tags_mismatch_error)
    return errorCode
  }
}
