import { merge as _merge } from 'lodash'
import it from 'core/translations/it'

export default _merge(it, {
  titles: {
    outbound_bthp: 'Outbound Certificato BTHP',
    sorting_details: 'Dettagli ventilazione',
  },
  simple_parcel_list: {
    to_read: 'Non letto',
    ok: 'Ok',
    force: 'Forza',
    force_parcel: 'Forza collo {{code}}',
    force_parcel_desc: 'Inserisci il PIN utente per forzare il collo',
    forced: 'Forzato',
    unexpected: 'Inatteso',
    remove: 'Rimuovi',
    invalid_pin: 'PIN utente non valido',
    loading: 'Caricamento elenco colli',
  },
  create_parcel_gate: {
    width: 'Larghezza',
    height: 'Altezza',
    depth: 'Profondità',
    tare: 'Tara',
    description: 'Descrizione',
  },
  misc: {
    sorted_quantity: 'Qt ventilata',
    sorted_date: 'Data ventilazione',
  },
  error: {
    //sorting_already_read: 'Articolo con seriale {{serial}} già ventilato nel gruppo {{group}}',
    sorting_already_read: 'Articolo con seriale {{serial}} già ventilato sul cliente {{group}}',
    on_destination: ' su destinazione {{destination}}',
    sorting_no_group: 'Articolo da non ventilare',
    item_not_sortable: 'Articolo con seriale {{serial}} da non ventilare',
    sorting_not_in_zone: "Articolo con seriale {{serial}} da non ventilare. L'articolo è nella zona {{zone}}",
    nfc_already_associated: "NFC già associato. Disassociarlo e ripetere l'operazione",
    tags_mismatch_error: 'Nessuna associazione trovata tra i tag',
  },
  serverError: {
    WRONG_ITEM_SUPPLIER: 'Rilevati item non associati al fornitore dichiarato.',
    QUANTITY_MISMATCH_ERROR: 'Quantità inserite non corrispondenti alle quantità rilevate.',
    ZONE_MISMATCH_ERROR: 'Zona inserita non corrispondente a quella rilevata.',
  },
  custom: {
    barcode: 'Barcode BC',
    barcodeBCTrim: 'Barcode BC trim',
    barcodeJDE: 'Barcode JDE',
    barcodeROQ: 'Barcode ROQ',
    barcodeROQTrim: 'Barcode ROQ trim',
    class: 'Classe',
    department: 'Dipartimento',
    dimensionSystem: 'Classe personalizzata',
    ean: 'EAN',
    eanIdPadre: 'EAN id padre',
    itemId: 'Item Id',
    stagioneROQ: 'Stagione ROQ',
    subclass: 'Model Type',
    subdepartment: 'Sottodipartimento',
    variantType: 'Tipologia',
    rfidEnabled: 'RFID abilitato',
    stato: 'Stato',
    ricondizionamentoFornitore: 'Fornitore ricondizionamento',
    codiceShelf: 'Codice scaffale',
    montante: 'Montante',
    ripiano: 'Ripiano',
    scaffale: 'Scaffale',
    ASSOCIATED: 'Associato',
    NOT_ASSOCIATED: 'Non associato',
    REPLACED_ASSOCIATION: 'Sostituito',
    identifiers: 'Identificatori',
    force_parcel: 'Forza parcel',
    force_parcel_message: 'Sei sicuro di voler confermare la forzatura di questo parcel?',
    pesa_collo: 'Pesa collo',
    peso_kg: 'Peso (kg)',
    wrong_packaging_code: 'Codice imballo errato',
    packaging_confirmed_no_weight: 'Stai confermando un collo senza peso, vuoi continuare?',
    attention: 'Attenzione',
    continue: 'Continua',
    model: 'Modello',
    sizeCode: 'Codice taglia',
    sizeDescription: 'Description taglia',
    serial: 'Seriale',
    identifier: 'Serial',
    product: 'Barcode BC',
    shift: 'Shift',
    destination: 'Destination',
    regDate: 'RegDate',
    listPosition: 'List position',
    list: 'List',
    customerName: 'Customer Name',
    regTime: 'RegTime',
    event: 'Event',
    customer: 'Customer',
    isCarryover: 'Carryover',
    carryoverValue: 'Carryover',
    madeIn: 'Made in',
    collectionCode: 'Collection',
    collectionDescription: 'Collection Description',
    collectionYear: 'Year',
    fatherProductValue: 'Father Product',
    genderValue: 'Gender',
    genderValueDescription: 'Gender Description',
    line: 'Brandline',
    lineDescription: 'Line Description',
    marchioDescription: 'Marchio Description',
    marchioValue: 'Marchio',
    merchandiseClassCode: 'Category',
    merchandiseClassDescription: 'Category Description',
    merchandiseSubclassCode: 'Sub-Category',
    merchandiseSubclassDescription: 'Sub-Category Description',
    modelPartColor: 'Model Part Color',
    modelPartColorSizeValue: 'Model Part Color Size',
    isPermanent: 'Permanent',
    permanentValue: 'Permanent',
    isSeasonal: 'Seasonal',
    seasonalValue: 'Seasonal',
    sleeveTypeValue: 'Sleeve Type',
  },
})
