export * from 'core/shared/utils'

export const checkSelfUserAttribute = (code) => {
  return code === 'AS400username'
}

export const enabledUtilities = () => {
  return false
}

export const enabledDisassociateItemTag = () => {
  return true
}
