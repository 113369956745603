import { Component } from 'react'
import styled from '@emotion/styled'
import { Icons, Box, Button, ItemInfoModal, StatusChip, Image } from 'stylewhere/components'
import { Products, TmrItem, GroupedItemRowProps } from 'stylewhere/api'
import { MotionStyle } from 'framer-motion'
import { closeModal, openModal } from 'stylewhere/shared/utils'
import { T, __ } from 'stylewhere/i18n'
import { AppStore } from 'stylewhere/shared'

interface State {
  modalVisible: boolean
}

export class GroupedItemRow extends Component<GroupedItemRowProps, State> {
  static Skeleton = styled(Box)``

  roundedLabel = (label: string, value?: string, style?: MotionStyle) => (
    <Box
      row
      mr={15}
      style={{
        backgroundColor: '#EDEDED',
        borderRadius: 5,
        ...style,
      }}
      pv={5}
      ph={10}
    >
      <SmallLabel>{label.toUpperCase()}</SmallLabel>
      <SmallValue>{value ?? ' ---'}</SmallValue>
    </Box>
  )

  getUnknownItemRowCode = () => {
    const { decodedItem } = this.props
    if (decodedItem.identifierCode) return decodedItem.identifierCode
    return 'Identifier N/A'
  }

  renderUnknownItemRow = () => {
    const { decodedItem, onRemoveItem, unknownHeight, mb } = this.props
    return (
      <>
        <Row mb={mb || 20} row height={unknownHeight || 120} center>
          <Box flex ph={16}>
            <ReadIdentifierCode>{this.getUnknownItemRowCode()}</ReadIdentifierCode>
          </Box>
          {!!onRemoveItem && (
            <Box m={8}>
              <Button onClick={() => onRemoveItem(decodedItem)} variant="secondary" size="small" circle>
                <Icons.Delete width={24} height={24} />
              </Button>
            </Box>
          )}
        </Row>
      </>
    )
  }

  showItemModal = () =>
    openModal({
      id: 'item-modal',
      modal: (
        <ItemInfoModal visible itemId={this.props.decodedItem.item?.id} onClose={() => closeModal('item-modal')} />
      ),
    })

  isWarningZone = () => {
    const { operation, itemZoneBase, decodedItem } = this.props
    if (operation && operation.enabledCheckItemZone && itemZoneBase && decodedItem.item && decodedItem.item.zone) {
      return decodedItem.item.zone.id !== itemZoneBase
    }
    return false
  }

  getSubClass = (product) => {
    let subclass
    if (product.attributes && product.attributes.subclass) {
      if (
        product.attributes.subclass.description &&
        product.attributes.subclass.description !== null &&
        product.attributes.subclass.description !== ''
      ) {
        subclass = product.attributes.subclass.description
      } else if (
        product.attributes.subclass.value &&
        product.attributes.subclass.value !== null &&
        product.attributes.subclass.value !== ''
      ) {
        subclass = product.attributes.subclass.value
      }
    }
    return subclass
  }

  renderItemRow = () => {
    const { decodedItem, onActionItem, hideProductInfo, mb, operation, disabledCounter } = this.props
    const { product } = decodedItem.item! as TmrItem

    const showDescription = product.attributes?.barcode?.value || product.code || product.description
    const subclass = this.getSubClass(product)

    const identifierCode = decodedItem.identifierCode || ''
    const identifiers =
      decodedItem.item && decodedItem.item.identifiers
        ? decodedItem.item.identifiers.filter((idf) => idf.code !== decodedItem.identifierCode && idf.role === 'serial')
        : []
    const showIdentifier = identifierCode !== '' || identifiers.length > 0

    const read = decodedItem.detected ?? 0 // + (unexpected ?? 0)
    const hasChecklist = operation && 'hasChecklist' in operation && operation.hasChecklist
    const counterValue = decodedItem.expected ? `${read}/${decodedItem.expected}` : `${read}${hasChecklist ? '/0' : ''}`
    return (
      <Row mb={mb || 20} row onClick={this.showItemModal} p={15}>
        {!hideProductInfo && product.code && AppStore.getShowProductImage() ? (
          <Box center>
            <Image src={Products.getImageUrl(product)} width={100} height={100} />
          </Box>
        ) : (
          <Box center ml={15} mr={10}>
            <Icons.Rfid style={{ width: 50 }} />
          </Box>
        )}
        <Box flex justify={'center'} mr={0} ml={15}>
          {showIdentifier && (
            <Box>
              {identifiers.map((identifier) => (
                <ReadIdentifierCode>{identifier.code}</ReadIdentifierCode>
              ))}
              {identifierCode !== '' && <IdentifierCode>{identifierCode}</IdentifierCode>}
            </Box>
          )}
          {!hideProductInfo && !!showDescription && (
            <Box bgGrey pv={8} ph={16} mt={8} mb={2} style={{ borderRadius: 5 }}>
              <b style={{ fontSize: 23 }}>{product.attributes?.barcode?.value || product.code}</b>
              {/* <b style={{ fontSize: 23 }}>{product.code}</b> */}
              <span style={{ fontSize: 16 }}>{product.description}</span>
              {subclass && (
                <span style={{ fontSize: 16 }}>
                  <b>{__(T.custom.subclass)}</b>: {subclass}
                </span>
              )}
            </Box>
          )}
          {decodedItem.item && decodedItem.item.zone && decodedItem.item.zone.place && (
            <StatusChip status={`${decodedItem.item?.zone?.place.code} - ${decodedItem.item?.zone?.code}`} mt={8} />
          )}
          <Box row>
            {decodedItem.statuses?.map((status, index) => (
              <StatusChip operation={this.props.operation} status={status} index={index} />
            ))}
          </Box>
          {this.isWarningZone() && <StatusChip customStatusType="warning" status={__(T.misc.warning_zone_items)} />}
        </Box>
        {hasChecklist && !disabledCounter && (
          <Box center ml={15}>
            <SmallCounter
              mr={0}
              ph={20}
              detected={read}
              expected={decodedItem.expected ?? 0}
              unexpected={decodedItem.unexpected ?? 0}
              noChecklist={!hasChecklist}
            >
              {counterValue}
            </SmallCounter>
          </Box>
        )}
        <Box center>
          {!!onActionItem && (
            <Box center mb={10} onClick={() => onActionItem(this.props.decodedItem)}>
              <Icons.Confirmed style={{ width: 38 }} />
            </Box>
          )}
          <Box center ml={10}>
            <Icons.Info style={{ width: 50 }} />
          </Box>
        </Box>
      </Row>
    )
  }

  render() {
    const { decodedItem } = this.props
    if (!decodedItem.item?.id) return this.renderUnknownItemRow()

    return this.renderItemRow()
  }
}

const Row = styled(Box)`
  margin-bottom: 20px;
  border: 2px solid #edecec;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background-color: ${({ theme }) => theme.background2};
`

const ReadIdentifierCode = styled.p`
  font-weight: bold;
  font-size: 16pt;
`
const IdentifierCode = styled.p`
  font-size: 16pt;
`
const SmallValue = styled.p`
  font-weight: bold;
  font-size: 12pt;
`
const SmallLabel = styled.p`
  font-size: 12pt;
  margin-right: 5px;
`
const SmallCounter = styled(Box)<{
  detected?: number
  expected?: number
  unexpected?: number
  noChecklist?: boolean
}>`
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  min-width: 100px;
  width: 100%;
  align-self: center;
  background-color: ${({ detected = 0, expected = 0, unexpected = 0, noChecklist = false }) => {
    if (noChecklist) return '#F0F0F0'
    switch (status) {
      case 'error':
        return '#FF8474'
      case 'warning':
        return '#FFEA67'
      case 'ignored':
        return '#DAE6ED'
      default:
        if (detected + unexpected > expected) return '#F2B57D'
        if (noChecklist) return '#75EBA8'
        if (unexpected > 0) return '#F2B57D'
        if (detected < expected) return '#EDEDED'
        if (detected === expected) return '#75EBA8'
        return '#F0F0F0'
    }
  }};
  border-radius: 10px;
`
