import { TransferToZone as Base } from 'core/api/TransferToZone'
import { AppStore } from 'stylewhere/shared'
import { api, responseErrorCheck } from 'stylewhere/api'

export class TransferToZone extends Base {    
  static confirm(data, async = false, requestConfig = undefined) {
    if (data.attributes) {
      data.attributes.workstationCode = AppStore.defaultWorkstation?.code
    } else {
      data.attributes = { workstationCode: AppStore.defaultWorkstation?.code }
    }
    return api.post(`${this.endpoint}${async ? '/asyncExecution' : ''}`, data, requestConfig).then(responseErrorCheck)
  }
}
