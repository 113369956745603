import { Component } from 'react'
import { ItemIdentifier } from 'stylewhere/api'
import styled from '@emotion/styled'
import { Box, Icons } from 'stylewhere/components'
import bwipjs from 'bwip-js'
import { __ } from 'stylewhere/shared/i18n'

interface Props {
  identifier: ItemIdentifier
  last?: boolean
  showStatus?: boolean
}

interface State {
  dataMatrix: string
}

export class IdentifierRow extends Component<Props, State> {
  state: State = {
    dataMatrix: '',
  }
  componentDidMount() {
    const { identifier } = this.props
    if (identifier.role === 'data_matrix') this.loadDataMatrix()
  }

  loadDataMatrix = () => {
    const { identifier } = this.props
    const canvas = document.createElement('canvas')
    bwipjs.toCanvas(canvas, {
      bcid: 'datamatrix',
      text: identifier.code,
    })
    this.setState({ dataMatrix: canvas.toDataURL('image/png') })
  }

  render() {
    const chipStyle = {
      warning: { border: '1px solid #FFEFC6', color: '#D6A52A' },
      error: { border: '1px solid #FF8474', color: '#e0402b' },
      default: { border: '1px solid #DAE6ED', color: '#5E5E5E' },
      success: { border: '1px solid #b7eb8f', color: '#52c41a' },
    }
    const { identifier, last, showStatus } = this.props
    let identifierChip = 'error'
    if (identifier.status && identifier.status === 'ASSOCIATED') identifierChip = 'success'
    if (identifier.status && identifier.status === 'REPLACED_ASSOCIATION') identifierChip = 'warning'
    return (
      <Box row mb={last ? 0 : 10}>
        {identifier.type === 'UHF_TAG' && (
          <Icons.Rfid width={20} height={20} style={{ marginTop: 10, marginRight: 10 }} />
        )}
        {identifier.type === 'NFC_Tag' && (
          <Icons.Nfc width={20} height={20} style={{ marginTop: 10, marginRight: 10 }} />
        )}
        {identifier.type !== 'UHF_TAG' && identifier.type !== 'NFC_Tag' && (
          <Icons.Serial width={20} height={20} style={{ marginTop: 10, marginRight: 10 }} />
        )}
        <IdentifierContainer row flex>
          {identifier.role !== 'data_matrix' && (
            <>
              <Code height={40} vcenter>
                {identifier.code}
              </Code>
              {showStatus && identifier.status && (
                <Status style={chipStyle[identifierChip]} p={'0px 10px'}>
                  {__('custom.' + identifier.status)}
                </Status>
              )}
            </>
          )}
          {identifier.role === 'data_matrix' && <DataMatrix alt="" src={this.state.dataMatrix} />}
        </IdentifierContainer>
        <Separator last={last} />
      </Box>
    )
  }
}

const DataMatrix = styled.img`
  margin-top: 10px;
  height: 100px;
  width: 100px;
`

const Separator = styled(Box)<{ last?: boolean }>`
  height: 1px;
  background-color: ${({ last }) => (last ? 'transparent' : '#e0e0e0')};
`

const IdentifierContainer = styled(Box)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Code = styled(Box)`
  font-style: normal;
  font-weight: bold;
  font-size: 13;
  line-height: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
`

const Status = styled(Box)<{ last?: boolean }>`
  font-style: normal;
  font-weight: bold;
  font-size: 13;
  height: 40px;
  line-height: 36px;
`
