import { Component } from 'react'
import { Box, Button, Input, Modal, Spacer } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  onConfirm: Function
  onClose: () => void
  visible: boolean
}

interface State {
  userPin: string
}

export class UserPinModal extends Component<Props, State> {
  state = {
    userPin: '',
  }

  changePin = (value) => {
    this.setState({ userPin: value })
  }

  confirmForce = async () => {
    const { userPin } = this.state
    if (!userPin) return

    this.props.onConfirm(userPin)
  }

  _close = () => {
    this.setState({ userPin: '' })
    this.props.onClose()
  }

  render() {
    const { userPin } = this.state
    const { visible } = this.props
    return (
      <Modal visible={visible} onClose={() => this._close()} size="lg" title={__(T.custom.force_parcel)}>
        <Box>
          <Input
            onChange={(value) => this.changePin(value)}
            value={userPin}
            showClear={false}
            type="password"
            label={__(T.simple_parcel_list.force_parcel_desc)}
          />
          <Spacer />
          <Box row justify="end">
            <Button onClick={() => this._close()} variant="default">
              {__(T.misc.cancel)}
            </Button>
            <Spacer />
            <Button onClick={() => this.confirmForce()} disabled={!userPin}>
              {__(T.misc.confirm)}
            </Button>
          </Box>
        </Box>
      </Modal>
    )
  }
}
