import { useState } from 'react'
import styled from '@emotion/styled'
import { GroupedParcelList as Base } from 'core/components/GroupedParcelList'
import { ShippingParcel } from 'stylewhere/api'
import {
  Box,
  ForceParcelByUserPinModal,
  GroupedItemRow,
  GroupedListRow,
  Icons,
  List,
  SmallTagCounter,
} from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { SimpleParcelListOperationConfig } from 'stylewhere/shared'
import { sleep, askUserConfirmation } from 'stylewhere/shared/utils'

interface Props {
  operation: SimpleParcelListOperationConfig
  parcels: ShippingParcel[]
  loading?: boolean
  removeParcel?: (parcel: ShippingParcel) => void
  refresh?: () => void
  forcedParcelCallback?: (code: string) => void
}

export function GroupedParcelList({
  operation,
  parcels,
  removeParcel,
  loading = false,
  refresh,
  forcedParcelCallback,
}: Props) {
  const [forceParcelModal, setForceParcelModal] = useState<ShippingParcel | false>(false)
  const [forcedParcels, setForcedParcels] = useState(() =>
    // Parcel già forzate in precedenza
    parcels.filter(
      ({ attributes }) =>
        attributes.outboundCertificatoForceUserId &&
        attributes.outboundCertificatoForceAuthorisingUserId &&
        attributes.outboundCertificatoForceDate
    )
  )

  if (operation.options.customExtensionCode !== 'cucinelliOutboundCertificato') {
    return <Base operation={operation} parcels={parcels} removeParcel={removeParcel} loading={loading} />
  }

  // Marca le parcel forzate per il render
  parcels.forEach((parcel) => (parcel.__forced = false))
  forcedParcels.forEach((parcel) => {
    parcel.__detected = true
    parcel.__forced = true
  })

  const rowRender = (parcel: ShippingParcel) => {
    let bgColor
    if (parcel.__detected) bgColor = '#75EBA8'
    if (parcel.__forced) bgColor = '#f2ea7d'
    return (
      <GroupedListRow
        onClick={
          refresh && !parcel.__detected && !parcel.__forced
            ? async () => {
                if (parcel.__forced) return
                if (forcedParcelCallback) {
                  if (
                    await askUserConfirmation(
                      __(T.custom.force_parcel),
                      __(T.custom.force_parcel_message),
                      __(T.misc.no),
                      __(T.misc.yes)
                    )
                  ) {
                    forcedParcelCallback(parcel.code)
                    setForcedParcels([...forcedParcels, parcel])
                    await sleep(300)
                    refresh?.()
                  }
                  return
                }
                setForceParcelModal(parcel)
                refresh()
              }
            : undefined
        }
        backgroundColor={bgColor}
        pv={16}
        pl={8}
        pr={16}
        style={{ height: 120 }}
      >
        <Box p={8} center>
          <Icons.Package width={60} />
        </Box>
        <Box justify="center" pl={8} flex style={{ overflow: 'auto' }}>
          <Code>{parcel.code}</Code>
        </Box>
        <Box row center ml={15}>
          <SmallTagCounter detected={parcel.parcelEntryQuantity || 0} />
        </Box>
      </GroupedListRow>
    )
  }

  if (loading) {
    return (
      <>
        <GroupedItemRow.Skeleton />
        <GroupedItemRow.Skeleton />
      </>
    )
  }

  const rows = parcels.sort((parcel: ShippingParcel) => {
    if (parcel.__unexpected) return -1
    if (parcel.__detected) return 1
    return 0
  })

  return (
    <>
      <List
        data={rows}
        emptyMessage={<span style={{ whiteSpace: 'break-spaces' }}>{__(T.messages.press_start_to_read)}</span>}
        rowRender={rowRender}
      />

      <ForceParcelByUserPinModal
        operationId={operation.id}
        parcel={forceParcelModal}
        onClose={async (forcedParcel) => {
          setForceParcelModal(false)
          if (forcedParcel) {
            setForcedParcels([...forcedParcels, forcedParcel])
          }
          await sleep(300)
          refresh?.()
        }}
        visible={!!forceParcelModal}
      />
    </>
  )
}

const Code = styled.div`
  font-weight: bold;
  font-size: 28px;
  white-space: nowrap;
`
