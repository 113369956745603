import { Routes as CoreRoutes } from 'core/pages'
import SimpleItemListReading from './SimpleItemList/SimpleItemListReading'
import SimpleParcelListReading from './SimpleParcelList/SimpleParcelListReading'

///////////////////////////////////////////////////////////////////////////////
// Custom & core overrides, change only this!
///////////////////////////////////////////////////////////////////////////////

const CustomDirectRoutes = {}

const CustomRoutes = {
  '/simple-item-list/:opCode/reading': { component: SimpleItemListReading },
  '/simple-parcel-list/:opCode/reading': { component: SimpleParcelListReading },
}

///////////////////////////////////////////////////////////////////////////////

export const Routes = { ...CustomDirectRoutes, ...CoreRoutes, ...CustomRoutes }
// eslint-disable-next-line
export type Routes = keyof typeof Routes
