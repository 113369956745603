import { Component } from 'react'
import styled from '@emotion/styled'
import { Box } from 'stylewhere/components'
import { DecodedItemSorting, SortingGroupRead } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { getEpc, getSerial } from 'stylewhere/utils'
import { SortingOperationConfig } from 'stylewhere/shared'

interface Props {
  operation: SortingOperationConfig
  currentItem: DecodedItemSorting
  currentSortingGroupRead?: SortingGroupRead
}

export class SortingProductInfo extends Component<Props> {
  getFirstValue = () => {
    const { currentItem, currentSortingGroupRead, operation } = this.props
    if (operation.enablePicking) {
      return currentSortingGroupRead &&
        currentSortingGroupRead.attributes &&
        currentSortingGroupRead.attributes.destination &&
        currentSortingGroupRead.attributes.destination !== ''
        ? currentSortingGroupRead.attributes.destination
        : 'n/a'
    }
    return getEpc(currentItem.item)
  }

  getThirdValue = () => {
    const { currentItem, currentSortingGroupRead, operation } = this.props
      return currentSortingGroupRead &&
        currentSortingGroupRead.attributes &&
        currentSortingGroupRead.attributes.list &&
        currentSortingGroupRead.attributes.list !== ''
        ? currentSortingGroupRead.attributes.list
        : 'n/a'
  }

  getFourthValue = () => {
    const { currentItem, currentSortingGroupRead, operation } = this.props
    if (operation.enablePicking) {
      return currentSortingGroupRead &&
        currentSortingGroupRead.attributes &&
        currentSortingGroupRead.attributes.listPosition &&
        currentSortingGroupRead.attributes.listPosition !== ''
        ? currentSortingGroupRead.attributes.listPosition
        : 'n/a'
    }
    return currentItem?.item?.product.attributes?.dimensionsystem?.value ?? 'n/a'
  }

  render() {
    const { currentItem, operation } = this.props
    return (
      <Box flex>
        <ProductInfo flex ph={25} justify="center">
          <ProductInfoLabel>{operation.enablePicking ? __(T.misc.destination) : 'EPC'}</ProductInfoLabel>
          <ProductInfoValue>{this.getFirstValue()}</ProductInfoValue>
        </ProductInfo>
        {currentItem && currentItem.item && (
          <>
            <ProductInfo flex ph={25} justify="center">
              <ProductInfoLabel>{__(T.misc.barcode_bc)}</ProductInfoLabel>
              <ProductInfoValue>{currentItem?.item?.product.attributes?.barcode?.value ?? 'n/a'}</ProductInfoValue>
            </ProductInfo>
            <ProductInfo flex ph={25} justify="center">
              <ProductInfoLabel>{__(T.misc.serial)}</ProductInfoLabel>
              <ProductInfoValue>{ getSerial( currentItem?.item) }</ProductInfoValue>
            </ProductInfo>
            <ProductInfo flex ph={25} justify="center">
              <ProductInfoLabel>{ __(T.misc.list) }</ProductInfoLabel>
              <ProductInfoValue>{this.getThirdValue()}</ProductInfoValue>
            </ProductInfo>
          </>
        )}
      </Box>
    )
  }
}

const ProductInfo = styled(Box)`
  border-bottom: 1px solid #5dd691;
  &:last-child {
    border: 0;
  }
  overflow: hidden;
`
const ProductInfoLabel = styled(Box)`
  font-size: 21px;
  line-height: 18px;
`
const ProductInfoValue = styled(Box)`
  padding-top: 5px;
  font-size: 23px;
  line-height: 20px;
  font-weight: bold;
`
