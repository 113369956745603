import { Component } from 'react'
import { ProductItemsModal as CoreProductItemsModal } from 'core/components'
import { Product } from 'stylewhere/api'

export class ProductItemsModal extends CoreProductItemsModal {
  getProductCode = (product: Product) => {
    if (
      product.attributes &&
      product.attributes.barcode &&
      product.attributes.barcode.value &&
      product.attributes.barcode.value !== ''
    )
      return product.attributes.barcode.value
    return product.code
  }
}
