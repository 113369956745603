import React from 'react'
import Base from 'core/pages/SimpleItemList/SimpleItemListReading'
import {
  AntennaButton,
  Box,
  Button,
  OperationReadingCounters,
  OperationReadingList,
  Page,
  Spacer,
  TagCounter,
  Input,
} from 'stylewhere/components'
import { SimpleItemListExtensions } from 'stylewhere/extensions'
import {
  Router,
  AppStore,
  getDataFromSchema,
  OperationReadingProps,
  OperationReadingState,
  RfidReader,
} from 'stylewhere/shared'
import { EnumerationValue, EnumerationValues, SimpleItemList, DecodedItem, BadRequestError } from 'stylewhere/api'
import {
  filterIgnoredAndErrorItems,
  filterIgnoredItems,
  showToast,
  showToastError,
  askUserConfirmation,
} from 'stylewhere/shared/utils'
import { T, __ } from 'stylewhere/shared/i18n'
import styled from '@emotion/styled'

type Packaging = {
  width: string
  height: string
  depth: string
  tare: string
  description: string
}

interface State extends OperationReadingState {
  packaging: EnumerationValue<Packaging>
  weight?: number | string
  confirming: boolean
}

export default class SimpleItemListReading extends Base<OperationReadingProps<State>, State> {
  formSchema = SimpleItemListExtensions.formSchema(this.operation, true)
  isModal = false

  isCreateParcelOperation = () => {
    return (
      this.operation.options &&
      this.operation.options.customExtensionCode &&
      (this.operation.options.customExtensionCode === 'cucinelliCreateParcelOperation' ||
        this.operation.options.customExtensionCode === 'cucinelliCreateParcelOperation-AS400')
    )
  }

  async componentDidMount() {
    await super.componentDidMount()

    const { formData } = this.state
    if (this.isCreateParcelOperation() && formData && formData.attributes && formData.attributes.packagingCode) {
      try {
        const result = await EnumerationValues.search<EnumerationValue<Packaging>>({
          enumerationTypeCode: 'PACKAGING',
          equalCodes: this.props.location.state.formData.attributes.packagingCode,
        })
        if (result && result.content && result.content.length === 1) {
          this.setState({ packaging: result.content[0] })
        }
      } catch (error) {}
    }
  }

  showConfirmButton = () => {
    const { formData } = this.state
    if (formData?.attributes?.enableAutoPrint || this.operation.confirmOnItemRead) {
      return null
    }
    return super.showConfirmButton()
  }

  closeFeebackModal = () => {
    this.postConfirmAction()
  }

  onConfirm = async () => {
    const { weight } = this.state
    if (this.antennaRef && this.antennaRef.current) {
      await this.antennaRef.current.stopReader()
    }

    if (this.isCreateParcelOperation() && (!weight || weight === '')) {
      if (
        await askUserConfirmation(
          __(T.custom.attention),
          __(T.custom.packaging_confirmed_no_weight),
          __(T.misc.cancel),
          __(T.custom.continue)
        )
      ) {
        this.setState({ confirming: true }, this._confirm)
      }
    } else {
      this.setState({ confirming: true }, this._confirm)
    }
  }

  _confirm = async () => {
    const { items, formData, weight } = this.state
    try {
      if (this.antennaRef && this.antennaRef.current) {
        await this.antennaRef.current.stopReader()
      }

      const confirmData = getDataFromSchema(formData, this.formSchema)
      try {
        await SimpleItemListExtensions.beforeConfirm(this.operation, confirmData, items)
      } catch (error) {
        this.setState({ confirming: false })
        return
      }
      if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing workstation place')

      if (this.isCreateParcelOperation()) {
        confirmData.attributes.userAction = 'CONFIRM'
        confirmData.attributes.weight = weight && weight !== '' ? weight.toString() : 0
      }

      const confirmResult = await SimpleItemList.save({
        ...confirmData,
        operationId: this.operation.id,
        operationPlaceId: AppStore.defaultWorkstation.placeId,
        itemIds: items.filter(filterIgnoredAndErrorItems).flatMap(({ item }) => (item?.id ? item.id : [])),
      })
      await SimpleItemListExtensions.afterConfirm(this.operation, confirmData, confirmResult)
      this.setState({ confirming: false })
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.generic_success, { code: this.operation.description }),
        status: 'success',
      })
      this.postConfirmAction()
    } catch (err) {
      this.setState({ confirming: false })
      if (err instanceof BadRequestError) {
        showToastError(err, err.title || __(T.error.error), this.isModal)
      } else {
        showToastError(err, __(T.error.error), this.isModal)
      }
    }
  }

  postConfirmAction = async () => {
    if (this.operation.postConfirmAction === 'disabled') {
      this.goBack()
    } else {
      if (
        await askUserConfirmation(
          __(T.confirm.post_confirm_action_title),
          this.operation.postConfirmAction === 'keepInput'
            ? __(T.confirm.post_confirm_action_keep_input)
            : __(T.confirm.post_confirm_action_change_input),
          __(T.misc.no),
          __(T.misc.yes)
        )
      ) {
        if (this.operation.postConfirmAction === 'keepInput') {
          RfidReader.clear()
          this.setState({ items: [], weight: undefined })
        } else {
          this.goBack()
        }
      } else {
        this.goDashboard()
      }
    }
  }

  goBack = () => {
    if (this.formSchema.length) {
      Router.navigate('/simple-item-list/:opCode', { opCode: this.operation.code })
    } else {
      Router.navigate('/')
    }
  }

  onClear = async () => {
    if (this.isCreateParcelOperation()) {
      const { items, formData, weight } = this.state
      if (items.filter(filterIgnoredItems).length > 0) {
        try {
          const confirmData = getDataFromSchema(formData, this.formSchema)
          await SimpleItemListExtensions.beforeConfirm(this.operation, confirmData, items)
          if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing workstation place')
          confirmData.attributes.userAction = 'CLEAR'
          confirmData.attributes.weight = weight?.toString() ?? 0
          const confirmResult = await SimpleItemList.save({
            ...confirmData,
            operationId: this.operation.id,
            operationPlaceId: AppStore.defaultWorkstation.placeId,
            itemIds: items.filter(filterIgnoredItems).flatMap(({ item }) => (item?.id ? item.id : [])),
          })
          await SimpleItemListExtensions.afterConfirm(this.operation, confirmData, confirmResult)
          this.setState({ weight: undefined })
          showToast({
            title: __(T.misc.success),
            description: __(T.messages.generic_success_canceled, { code: this.operation.description }),
            status: 'success',
          })
        } catch (err) {
          showToastError(err, __(T.error.error), this.isModal)
        }
      }
    }

    RfidReader.clear()
    this.setState({ items: [] })
  }

  getWeight = async () => {
    try {
      const res = await RfidReader.getWeight()
      if (!res.ok) throw new Error('Errore durante la lettura')
      this.setState({ weight: res?.data?.weight })
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  changeWeight = (val) => {
    val = val.replace(',', '.')
    if (isNaN(val)) val = ''
    if (val !== '' && parseFloat(val) < 0) val = '0'
    if (val !== '' && val.length > 1 && parseInt(val[0], 10) === 0 && val[1] !== '.') {
      val = val.slice(1)
    }
    this.setState({ weight: val !== '' ? val : '' })
  }

  setChangeData = async (fd) => {
    const { packaging } = this.state
    const packCode = packaging && packaging.code ? packaging.code : ''
    if (fd && fd.attributes && fd.attributes.packagingCode && fd.attributes.packagingCode !== packCode) {
      const result = await EnumerationValues.search<EnumerationValue<Packaging>>({
        enumerationTypeCode: 'PACKAGING',
        equalCodes: fd.attributes.packagingCode,
      })
      if (result && result.content && result.content.length === 1) {
        this.setState({ packaging: result.content[0], formData: fd })
      } else {
        this.setState({ formData: fd })
      }
    } else {
      this.setState({ formData: fd })
    }
  }

  getHeaderDetails = (schema) => {
    const { formData } = this.state
    if (this.checkChangeData(schema)) {
      return {
        details: {
          data: formData,
          formSchema: schema,
          operationId: this.operation.id,
          setFormData: async (fd) => {
            if (!(await SimpleItemListExtensions.formDataIsValid(fd, this.operation, schema))) return
            this.setChangeData(fd)
            this.setRfidReaderDecode()
          },
          resetFormData: async (fd) => {
            this.setState({ formData: fd })
          },
        },
      }
    }
    return {
      details: {
        data: formData,
        formSchema: schema,
      },
    }
  }

  customRender() {
    // Location assignment non ha la modale di modifica (prop header di Page)
    const { items, loading, packaging, weight } = this.state
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        header={this.getHeaderDetails(this.formSchema)}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter detected={items.filter(filterIgnoredAndErrorItems).length} />
            <AntennaButton
              ref={this.antennaRef}
              decodeRequest={this.getDecodeRequest()}
              onItemDecoded={this.onDecodedItemCallback}
              onClear={this.onClear}
              hideClear={items.length === 0}
            />
            <Spacer />
            <OperationReadingCounters operation={this.operation} items={items} />
          </Box>
          {this.showConfirmButton()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          <OperationReadingList
            removeItemCallback={this.removeItem}
            // actionItemCallback={() => alert('')}
            extension={SimpleItemListExtensions}
            items={items}
            operation={this.operation}
          />
          {this.isCreateParcelOperation() && (
            <BalanceCard row>
              <Box flex>
                <Box row style={{ width: '100%' }}>
                  <Box flex row>
                    {__(T.create_parcel_gate.width)}
                    <strong>: {packaging?.attributes?.width}</strong>
                  </Box>
                  <Box flex row>
                    {__(T.create_parcel_gate.height)}
                    <strong>: {packaging?.attributes?.height}</strong>
                  </Box>
                </Box>
                <Box row>
                  <Box flex row>
                    <>
                      {__(T.create_parcel_gate.depth)}
                      <strong>: {packaging?.attributes?.depth}</strong>
                    </>
                  </Box>
                  <Box flex row>
                    {__(T.create_parcel_gate.tare)}
                    <strong>: {packaging?.attributes?.tare}</strong>
                  </Box>
                </Box>
                <Box flex row style={{ marginTop: 10, wordBreak: 'normal' }}>
                  <p>
                    {__(T.create_parcel_gate.description)}: <b>{packaging?.attributes?.description ?? '--'}</b>
                  </p>
                </Box>
              </Box>
              <Box row center>
                <Button size="medium" style={{ height: 65 }} title={__(T.custom.pesa_collo)} onClick={this.getWeight} />
                <WeightBox center>
                  <Input
                    onChange={(value) => this.changeWeight(value)}
                    currentValue={weight ? weight.toString() : ''}
                    showClear={false}
                    type="text"
                    label={__(T.custom.peso_kg)}
                  />
                </WeightBox>
              </Box>
            </BalanceCard>
          )}
        </Page.Content>
      </Page>
    )
  }

  isCustomRender = () => {
    return this.operation.code === 'location-assignment' || this.operation.code === 'create-parcel-gate'
  }

  render() {
    if (this.isCustomRender()) return this.customRender()
    return super.render()
  }
}

const BalanceCard = styled(Box)`
  background-color: white;
  border-radius: 15px;
  margin-top: 14px;
  padding: 14px;
`

const WeightBox = styled(Box)`
  margin-left: 10px;
  height: 65px;
  min-width: 140px;
  max-width: 140px;
`
